import React from 'react'
import ReactDOM from 'react-dom/client'
import 'react-toastify/dist/ReactToastify.css'
import './styles/main.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ProductsProvider } from './context/ProductsProvider'
import { OrdersProvider } from './context/OrdersProvider'
import { UserProvider } from './context/UserProvider'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <UserProvider>
    <OrdersProvider>
      <ProductsProvider>
        <App />
      </ProductsProvider>
    </OrdersProvider>
  </UserProvider>
);

reportWebVitals()
