import axios from 'axios'
import Cookies from 'js-cookie'

export const getProducts = async ( ) =>  {
  const storedToken = Cookies.get('token')
  const headers = {
    'Accept': 'application/json',
    'Authorization': `Bearer ${storedToken}`
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/products`, { headers })
    return response.data.data
  } catch (error) {
    console.log("🚀 ~ file: orderService.js:19 ~ getOrders ~ error:", error)
    throw error
  }
}