import React from 'react'
import Button from './Button'

const Thanks = ({
  textButton,
  linkButton,
  textLine1,
  textLine2
}) => {
  return (
    <section className='min-h-screen flex flex-col items-center justify-center text-center px-4'>
      <header className='mb-10'>
        <i className='fas fa-check-circle fa-2x text-green-600'></i>
        <span className='font-bold text-3xl pl-4'>Gracias</span>
      </header>
      <div className='mb-16'>
        <p className='font-light'>{textLine1}</p>
        <p className='font-light'>{textLine2}</p>
      </div>
      <Button
        mode={'dark'}
        action={'link'}
        href={linkButton}
        text={textButton}
        clase={'flex mx-auto justify-center max-w-[200px]'}
      />
    </section>
  )
}

export default Thanks