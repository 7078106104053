import { useContext, useEffect, useState } from 'react'
import { ProductsContext } from './ProductsContext'
import { sedOrderServices, sendProducToAddService, sendProducToRemoveService, sendProducToUpdateService } from '../services/ordersService'
import { getProducts } from '../services/productsService'
import { getInfo } from '../services/businessService.js'
import { UserContext } from './UserProvider'

export const ProductsProvider = ({ children }) => {

  const [products, setProducts] = useState([])
  const [firstItems, setFirstItems] = useState([])
  const [secondItems, setSecondItems] = useState([])
  const [cart, setCart] = useState([]) // State para el carrito
  const [minimum_amount, setMinimum_amount] = useState(null)
  const [sendingOrder, setSendingOrder] = useState(false)
  const [statusOrder, setStatusOrder] = useState(false)
  const [statusOrderError, setStatusOrderError] = useState(false)
  const { user } = useContext(UserContext)

  const days = user?.days
  const clientId = user?.id

  const minimum = getInfo()

  const getAllProducts = async () => {
    try {
      const allProducts = await getProducts()
      setProducts(allProducts)
      // Filtrar productos por entrega
      const first = allProducts.filter((product) => {
        return product.deliveries.some((entrega) => entrega.name === 'Martes' || entrega.name === 'Miércoles')
      })
      const second = allProducts.filter((product) => {
        return product.deliveries.some((entrega) => entrega.name === 'Jueves' || entrega.name === 'Viernes')
      })
      const firstProducts = first.map((item) => {
        return { delivery: days[0].id, generateId: `P${item.id}D1`, ...item }
      })
      const secondProducts = second.map((item) => {
        return { delivery: days[1].id, generateId: `P${item.id}D2`, ...item };
      })

      setFirstItems(firstProducts)
      setSecondItems(secondProducts)

    } catch (error) {
      console.log("🚀 ~ file: ProductsProvider.jsx:19 ~ getAllProducts ~ error:", error)
      throw error
    }
  }

  // Función para agregar un producto al carrito
  const addToCart = (product, dayId) => {
    const existingItem = cart.find((item) => item.id === product.id && item.dayId === dayId)
    if (existingItem) {
      // Si el producto ya está en el carrito, actualiza la cantidad
      setCart((prevCart) =>
        prevCart.map((item) =>
          item.id === product.id && item.dayId === dayId
            ? { ...item, quantity: item.quantity + 1 }
            : item
        )
      )
    } else {
      // si el producto tiene cantidad significa que está editando un pedido
      if ( product.quantity ) {
        setCart((prevCart) => [
          ...prevCart,
          { ...product, dayId },
        ])
      } else {
        // Si el producto no está en el carrito, agrégalo con cantidad 1
        setCart((prevCart) => [
          ...prevCart,
          { ...product, dayId, quantity: 1 },
        ])
      }
    }

  }

  // Función para restar un producto del carrito
  const removeFromCart = (product, dayId) => {
    setCart((prevCart) =>
      prevCart
        .map((item) =>
          item.id === product.id && item.dayId === dayId
            ? { ...item, quantity: item.quantity - 1 }
            : item
        )
        .filter((item) => item.quantity > 0)
    )
  }

  // Actualizar la cantidad en el carrito
  const handleQuantityChange = (product, dayId, newQuantity) => {
    // Agrega la lógica para actualizar la cantidad en el carrito aquí
    if (newQuantity >= 0) {
      if (cart.find((item) => item.id === product.id && item.dayId === dayId)) {
        setCart((prevCart) =>
          prevCart.map((item) =>
            item.id === product.id && item.dayId === dayId
              ? { ...item, quantity: newQuantity }
              : item
          )
        )
      } else {
        setCart((prevCart) => [
          ...prevCart,
          { ...product, dayId, quantity: newQuantity },
        ])
      }
    }
  }

  // Función para calcular el subtotal de un día de entrega
  const calculateSubtotal = (dayId) => {
    return cart
      .filter((item) => item.dayId === dayId)
      .reduce((total, item) => total + item.price * item.quantity, 0)
  }

  // Función para calcular el total general
  const calculateTotal = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0)
  }

  // Enviar nuevo pedido o pedido editado
  const sendOrder = async ( editOrder, selectedOrder ) => {
    setSendingOrder(true)
    if ( editOrder ) {
      try {
        const productToUpdate = [], prodcutToAdd = []
        cart.forEach((product) => {
          const items = product.dayId === 2 || product.dayId === 3
          ? selectedOrder.orders[0].order_details.filter(order => order.product_id === product.id)
          : selectedOrder.orders.length === 2
          ? selectedOrder.orders[1].order_details.filter(order => order.product_id === product.id)
          : selectedOrder.orders[0].order_details.filter(order => order.product_id === product.id)

          const prodcutAux = {
            generateId: product.generateId,
            line_id: product.line_id ?? null, // line_id
            order_id: product.order_id // order_id
                      ? product.order_id
                      : product.dayId === 2 || product.dayId === 3
                      ? selectedOrder.orders[0].id
                      : selectedOrder.orders.length === 2
                      ? selectedOrder.orders[1].id
                      : selectedOrder.orders[0].id,
            product_price: product.price, // product_price
            quantity: product.quantity, // quantity
            product_id: product.product_id ? product.product_id : product.id // product_id
          }

          if (items.length > 0) {
            if (items[0].quantity !== product.quantity) {productToUpdate.push(prodcutAux)}
          } else {
            prodcutToAdd.push(prodcutAux)
          }
        })

        if (productToUpdate.length  > 0) {
          productToUpdate.forEach( async (product) => {
            if (product.quantity === 0) {
              const data = await sendProducToRemoveService(product.line_id)
              if ( data && data.message ) {
                setSendingOrder(false)
                setCart([])
                setStatusOrder(true)
              } else {
                setStatusOrderError(true)
              }
            } else {
              const data = await sendProducToUpdateService(product)
              if ( data && data.message ) {
                setSendingOrder(false)
                setCart([])
                setStatusOrder(true)
              } else {
                setStatusOrderError(true)
              }
            }
          })
          localStorage.removeItem('item')
        }

        if (prodcutToAdd.length  > 0) {
          prodcutToAdd.forEach( async (product) => {
            const data = await sendProducToAddService(product)
            if ( data && data.message ) {
              setSendingOrder(false)
              setCart([])
              setStatusOrder(true)
            } else {
              setStatusOrderError(true)
            }
          })
        }

      } catch (error) {
        console.log("🚀 ~ file: ProductsProvider.jsx:168 ~ sendOrder ~ error:", error)
        setSendingOrder(false)
        setStatusOrderError(true)
      }
    } else {
      try {
        const products = cart.map(product => (
          {
            delivery: product.delivery,
            id: product.id,
            price: product.price,
            quantity: product.quantity
          }
        ))
        const order = {
          client_id: clientId,
          products: products
        }
        const response = await sedOrderServices(order)
        if (response.data && response.data.message === 'El pedido se creo con éxito !') {
          setCart([])
          setSendingOrder(false)
          setStatusOrder(true)
        } else {
          setSendingOrder(false)
          setStatusOrderError(true)
        }
      } catch (error) {
        console.log("🚀 ~ file: ProductsProvider.jsx:160 ~ sendOrder ~ error:", error)
        setSendingOrder(false)
        setStatusOrderError(true)
      }
    }
  }


  // Función para cargar el carrito con los detalles del pedido
  const loadCartFromOrder = (selectedOrder) => {
    try {
      if (selectedOrder.orders[0].delivery_name === "Martes" || selectedOrder.orders[0].delivery_name === "Miércoles") {
        selectedOrder.orders[0].order_details.forEach((product) => {
          const item = firstItems.find((item) => {
            return item.id === product.product_id
          })
          addToCart({ ...item, quantity: product.quantity, order_id: product.order_id, line_id: product.id }, days[0].id)
        })
      } else if (selectedOrder.orders[0].delivery_name === "Jueves" || selectedOrder.orders[0].delivery_name === "Viernes") {
        selectedOrder.orders[0].order_details.forEach((product) => {
          const item = secondItems.find((item) => {
            return item.id === product.product_id
          })
          addToCart({ ...item, quantity: product.quantity, order_id: product.order_id, line_id: product.id }, days[1].id)
        })
      }
      
      if (selectedOrder.orders[1]) {
        selectedOrder.orders[1].order_details.forEach((product) => {
          const item = secondItems.find((item) => {
            return item.id === product.product_id
          })
          addToCart({ ...item, quantity: product.quantity, order_id: product.order_id, line_id: product.id }, days[1].id)
        })
      }
    } catch (error) {
      console.log("🚀 ~ file: ProductsProvider.jsx:206 ~ loadCartFromOrder ~ error:", error)
    }
  }

  useEffect(() => {
    setMinimum_amount(minimum.minimum_amount ?? 8000)
  }, [])


  return (
    <ProductsContext.Provider
      value={{
        getAllProducts,
        products,
        firstItems,
        secondItems,
        cart,
        addToCart,
        removeFromCart,
        handleQuantityChange,
        calculateSubtotal,
        calculateTotal,
        sendingOrder,
        sendOrder,
        minimum_amount,
        statusOrder,
        setStatusOrder,
        setCart,
        loadCartFromOrder,
        statusOrderError,
        setStatusOrderError,
      }}>
      {children}
    </ProductsContext.Provider>
  )
}