import { useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { OrdersContext } from '../context/OrdersProvider'
import moment from 'moment/moment'
import 'moment/locale/es'
import { useBlockDays } from '../hooks/blockDays'
import Navbar from '../components/layouts/Navbar'
import Header from '../components/layouts/Header'
import CalculateAmountWeekly from '../components/orders/CalculateAmountWeekly'
import CalculateAmount from '../components/orders/CalculateAmount'
import Presentation from '../components/orders/Presentation'
import Button from '../components/Button'
import { UserContext } from '../context/UserProvider'

const OrderDetails = () => {

  const { selectedOrder, showOrderDetails, loadingOrder } = useContext(OrdersContext)
  const { user } = useContext(UserContext)
  let { idendifier } = useParams()

  const allowBuy = useBlockDays()
  const days = user?.days
  const discount = user?.discount


  useEffect(() => {
    showOrderDetails(idendifier)
  }, [])

  return (
    <>
      <Navbar />
      <Header title='Detalle del pedido' />
      {
        loadingOrder ? (
          <section className='main-section w-full flex items-center'>
            <svg className='animate-spin px-1 h-8 w-8 text-white mx-auto' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className='opacity-25' cx="12" cy="12" r="10" stroke="#E6AA41" strokeWidth="4"></circle>
              <path className='opacity-75' fill="#E6AA41" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </section>
        ) : (
          <section className='main-section px-4 w-full pb-20'>
            <div className='max-w-5xl mx-auto flex pt-6 mb-14 justify-between items-center'>
              <div>
                Pedido semanal Nro: {selectedOrder.identifier}<br />
                Total: <CalculateAmountWeekly orders={selectedOrder.orders} />
              </div>
              <div>
                {
                  selectedOrder.state === 'Pendiente' && allowBuy ? (
                    <Link
                      className='inline-flex items-center px-4 py-2 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring focus:ring-gray-300 disabled:opacity-25 transition bg-yellow-500 hover:bg-yellow-300'
                      to={`/products?editOrder=${selectedOrder.identifier}`}><i className='fa fa-solid fa-pencil mr-2'></i> Editar
                    </Link>
                  ) : null
                }
              </div>
            </div>
            {
              selectedOrder && 
              selectedOrder.orders.map(order => (
                <div key={order.id}>
                  <hr /> <hr /> <hr />
                  <div className='max-w-5xl mx-auto flex flex-col pt-6 mb-14'>
                    <div className='details flex flex-col lg:flex-row lg:justify-between lg:mb-5'>
                      <div>
                        <p>Día de Entrega: </p>
                        <h3 className='font-bold text-lg mb-3'>{
                          order.delivery_name === 'Martes' || order.delivery_name === 'Miércoles'
                            ? days[0].name
                            : days[1].name
                        }</h3>
                      </div>
                      <div>
                        <p>Fecha: </p>
                        <h3 className='font-bold text-lg mb-3'>{moment(order.created_at).format('L')}</h3>
                      </div>
                      <div>
                        <p>Estado: </p>
                        <h3 className='font-bold text-lg mb-3'>{order.state}</h3>
                      </div>
                      <div>
                        <p>Subtotal:</p>
                        <h3 className='font-bold text-lg mb-3'>
                          <CalculateAmount products={order.order_details} discount={discount} />
                        </h3>
                      </div>
                      <div>
                        <p>Nro:</p>
                        <h3 className='font-bold text-lg mb-3'>
                          {order.id}
                        </h3>
                      </div>
                    </div>
                    <p className='mb-2'>Productos:</p>
                    <ul className='border-solid border border-gray-200 order-list-products'>
                      <li className='flex py-3 px-2 lg:px-8 bg-white font-bold border-b-2 border-gray-200 border-solid'>
                        <span className='count w-[5%] lg:w-[10%]'>
                          Cant.
                        </span>
                        <span className='name w-[55%] lg:w-[55%]'>
                          Nombre
                        </span>
                        <span className='presentations w-[20%]'>
                          Present.
                        </span>
                        <span className='count w-[20%] text-right'>Precio</span>
                      </li>
                      {
                        order.order_details.map(product => (
                          <li className='flex py-3 px-2 lg:px-8' key={product?.id}>
                            <span className='count w-[5%] lg:w-[10%]'>{product?.quantity}</span>
                            <span className='name w-[55%] lg:w-[55%] font-semibold'>
                              {
                                product?.product_name
                              }
                            </span>
                            <span className='presentations w-[20%]'>
                              <Presentation id={product?.product_id} />
                            </span>
                            <span className='count w-[20%] text-right'>${product?.product_price.toLocaleString('es-AR')}</span>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              ))
            }
            <Button
              mode={'dark'}
              action={'link'}
              href={'/orders'}
              text={'Volver'}
              clase={'flex mx-auto justify-center max-w-[200px]'}
            />
          </section>
        )
      }
    </>
  )
}

export default OrderDetails