import { useContext } from 'react'
import { UserContext } from '../context/UserProvider'
import Navbar from '../components/layouts/Navbar'
import Header from '../components/layouts/Header'

const Account = () => {
  const { user, logoutUser } = useContext(UserContext)

  return (
    <>
      <Navbar />
      <Header title={'Cuenta'} />
      <section className={`main-section px-4 w-full pb-5 min-h-screen`}>
        <div className='pt-4 max-w-7xl mx-auto lg:flex'>
          <div className='lg:w-1/3 lg:pr-10'>
            <div className='rounded bg-white text-center py-6 mb-5'>
              <h1 className='font-bold text-lg mb-5'>{ user.charge_name }</h1>
              <p className='text-slate-400'>{ user.charge_email }</p>
              <p className='text-slate-400'>{ user.charge_telephont }</p>
              <p className='text-slate-400'>{ user.charge_address }</p>
            </div>
          </div>
          <div className='lg:w-2/3 lg:pr-10'>
            <div className='rounded bg-white p-6 lg:mb-5'>
              <h1 className='font-bold text-lg mb-5'>Datos del Negocio:</h1>
              <p className='mb-3'><span className='font-bold'>Nombre/Razón Social: </span> <span className='text-slate-500'>{ user.name }</span></p>
              <p className='mb-3'><span className='font-bold'>E-mail: </span> <span className='text-slate-500'>{ user.name }</span></p>
              <p className='mb-3'><span className='font-bold'>Teléfono: </span> <span className='text-slate-500'>{ user.telephont }</span></p>
              <p className='mb-3'><span className='font-bold'>CUIT: </span> <span className='text-slate-500'>{ user.cuit }</span></p>
              <p className='mb-3'><span className='font-bold'>Dirección: </span> <span className='text-slate-500'>{ user.address }</span></p>
              <p className='mb-3'><span className='font-bold'>Provincia: </span> <span className='text-slate-500'>{ user.state }</span></p>
              <p className='mb-3'><span className='font-bold'>Localidad: </span> <span className='text-slate-500'>{ user.city }</span></p>
              <p className='mb-3'><span className='font-bold'>Días de entregas: </span> <span className='text-slate-500'>{ user.days[0].name } y { user.days[1].name }</span></p>
              <p className='mb-3'><span className='font-bold'>Descuento: </span> <span className='text-slate-500'>{ user.discount }%</span></p>
            </div>
          </div>
        </div>
        <p className='text-center my-6 cursor-pointer lg:hidden' onClick={logoutUser}>Cerrar sesión</p>
      </section>
    </>
  )
}

export default Account