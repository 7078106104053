import { useContext, useState, useEffect } from 'react'
import { ProductsContext } from '../context/ProductsContext'
import { OrdersContext } from '../context/OrdersProvider'
import Navbar from '../components/layouts/Navbar'
import Header from '../components/layouts/Header'
import { useBlockDays } from '../hooks/blockDays'
import Modal from '../components/Modal'
import { useLocation } from 'react-router-dom'
import { UserContext } from '../context/UserProvider'

const Products = () => {
  // Obtén la información de productos y días de entrega del estado global
  const {
    getAllProducts,
    firstItems,
    secondItems,
    cart,
    addToCart,
    removeFromCart,
    handleQuantityChange,
    calculateSubtotal,
    calculateTotal,
    sendingOrder,
    sendOrder,
    minimum_amount,
    statusOrder,
    setStatusOrder,
    loadCartFromOrder,
    setStatusOrderError,
    statusOrderError,
  } = useContext(ProductsContext)
  const { selectedOrder, showOrderDetails } = useContext(OrdersContext)
  const { user } = useContext(UserContext)

  const days = user?.days
  const discount = user?.discount
  const [tab, setTab] = useState(0)
  const allowBuy = useBlockDays()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const editOrder = queryParams.get('editOrder')

  useEffect(() => {
    const products = getAllProducts()
    if (  products ) {
      if (days) setTab(days[0].id)
      if (editOrder) {
        showOrderDetails(editOrder)
        loadCartFromOrder(selectedOrder)
      }
    }
  }, [])

  return (
    <>
      <Navbar />
      <Header title={'Productos'} />
      <section className={`main-section px-4 w-full pb-24 min-h-screen`}>
        <div className='text-center lg:flex pt-4 max-w-7xl mx-auto lg:px-8'>
          <div className='w-full lg:w-2/3 lg:pr-10'>

            {
              !allowBuy ? (
                <div className="bg-blue-100 border-solid border-t-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 shadow-md block md:hidden" role="alert">
                  <div className="flex">
                    <div className="py-1">
                      <svg className="fill-current h-6 w-6 text-blue-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg>
                    </div>
                    <div className='text-left'>
                      <p className="font-bold">
                        Información
                      </p>
                      <p className="text-sm">Los pedidos solo se pueden realizar a partir del viernes 18hs hasta el domingo 21hs.</p>
                    </div>
                  </div>
                </div>
              ) : null
            }

            <section className='tab'>
              <article className='tab__header flex border-b border-solid border-slate-300'>
                {days.map((day) => (
                  <div key={day.id} className={`tab__header--item relative cursor-pointer font-bold text-slate-500 py-4 pr-4 ${tab === day.id ? 'active' : ''}`} onClick={() => setTab(day.id)}>Entrega {day.name} </div>
                ))}
              </article>

              {days.map((day) => (
                <div key={day.id}>
                  {day.id === 2 || day.id === 3
                    ? firstItems.map((product) => (
                      <article key={product.id} className={`tab__body ${tab === days[0].id ? 'active' : ''}`}>
                        <div className='products lg:flex flex-col'>
                          <div className='products__items my-3 flex flex-col rounded bg-white overflow-hidden justify-between'>
                            <div className='flex'>
                              <div className='products__items--image m-auto'>
                                <img
                                  src={`${process.env.REACT_APP_DOMAIN}/${product.image}`}
                                  className='object-cover object-center block h-full w-full'
                                  alt={product.name}
                                />
                              </div>
                              <div className='products__items--content pr-4 pl-6 py-3 relative flex flex-col'>
                                <h3 className='products__items--title font-medium text-md mb-1 leading-5 text-left'>{product.name}</h3>
                                <p className='products__items--description font-thin text-sm mb-2 leading-4 text-left'>{product.description}</p>
                                <div className='flex'>
                                  <span className='products__items--price text-sm mt-auto mr-auto'>{product.value} {product.presentation}</span>
                                  {
                                    product.cooled === 1 && (
                                      <span className='products__items--price text-sm mt-auto ml-auto text-blue-700'>Refrigerado</span>
                                    )
                                  }
                                </div>
                              </div>
                            </div>
                            <div className='lg:-mt-12'>
                              <ul className='pt-2 flex justify-between items-center px-4 pb-3 lg:py-1 lg:pl-36 relative z-10'>
                                <li className='unit-price'>
                                  <span className='products__items--price font-black mt-auto'>$ {product.price.toLocaleString('es-AR')}</span>
                                </li>
                                {
                                  allowBuy ? (
                                    <li className='quantity products__items--quantity'>
                                      <span className='cursor-pointer border border-solid border-slate-300 px-2' onClick={() => removeFromCart(product, day.id)}>-</span>
                                      <input
                                        type='number'
                                        min={0}
                                        className='pl-2 border border-solid border-slate-300 text-right h-auto'
                                        value={
                                          cart.find((item) => item.id === product.id && item.dayId === day.id)
                                            ? cart.find((item) => item.id === product.id && item.dayId === day.id).quantity
                                            : 0
                                        }
                                        onChange={
                                          (e) => handleQuantityChange(product, day.id, parseInt(e.target.value))
                                        } />
                                      <span className='cursor-pointer border border-solid border-slate-300 px-2' onClick={() => addToCart(product, day.id)}>+</span>
                                    </li>
                                  ) : null
                                }
                              </ul>
                            </div>
                          </div>
                        </div>
                      </article>
                    ))
                    : secondItems.map((product) => (
                      <article key={product.id} className={`tab__body ${tab === days[1].id ? 'active' : ''}`}>
                        <div className='products lg:flex flex-col'>
                          <div className='products__items my-3 flex flex-col rounded bg-white overflow-hidden justify-between'>
                            <div className='flex'>
                              <div className='products__items--image m-auto'>
                                <img
                                  src={`${process.env.REACT_APP_DOMAIN}/${product.image}`}
                                  className='object-cover object-center block h-full w-full'
                                  alt='Papas fritas'
                                />
                              </div>
                              <div className='products__items--content pr-4 pl-6 py-3 relative flex flex-col'>
                                <h3 className='products__items--title font-medium text-md mb-1 leading-5 text-left'>{product.name}</h3>
                                <p className='products__items--description font-thin text-sm mb-2 leading-4 text-left'>{product.description}</p>
                                <div className='flex'>
                                  <span className='products__items--price text-sm mt-auto mr-auto'>{product.value} {product.presentation}</span>
                                  {
                                    product.cooled === 1 && (
                                      <span className='products__items--price text-sm mt-auto ml-auto text-blue-700'>Refrigerado</span>
                                    )
                                  }
                                </div>
                              </div>
                            </div>
                            <div className='lg:-mt-12'>
                              <ul className='pt-2 flex justify-between items-center px-4 pb-3 lg:py-1 lg:pl-36 relative z-10'>
                                <li className='unit-price'>
                                  <span className='products__items--price font-black mt-auto'>$ {product.price.toLocaleString('es-AR')}</span>
                                </li>
                                {
                                  allowBuy ? (
                                    <li className='quantity products__items--quantity'>
                                      <span className='cursor-pointer border border-solid border-slate-300 px-2' onClick={() => removeFromCart(product, day.id)}>-</span>
                                      <input
                                        type='number'
                                        min={0}
                                        className='pl-2 border border-solid border-slate-300 text-right h-auto'
                                        value={
                                          cart.find((item) => item.id === product.id && item.dayId === day.id)
                                            ? cart.find((item) => item.id === product.id && item.dayId === day.id).quantity
                                            : 0
                                        }
                                        onChange={
                                          (e) => handleQuantityChange(product, day.id, parseInt(e.target.value))
                                        } />
                                      <span className='cursor-pointer border border-solid border-slate-300 px-2' onClick={() => addToCart(product, day.id)}>+</span>
                                    </li>
                                  ) : null
                                }
                              </ul>
                            </div>
                          </div>
                        </div>
                      </article>
                    ))
                  }
                </div>
              ))}

            </section>



          </div>
          <div className='lg:w-1/3'>
            {
              !allowBuy ? (
                <div className="bg-blue-100 border-solid border-t-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 shadow-md mt-14 hidden md:block" role="alert">
                  <div className="flex">
                    <div className="py-1">
                      <svg className="fill-current h-6 w-6 text-blue-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg>
                    </div>
                    <div className='text-left'>
                      <p className="font-bold">
                        Información
                      </p>
                      <p className="text-sm">Los pedidos solo se pueden realizar a partir del viernes 18hs hasta el domingo 21hs.</p>
                    </div>
                  </div>
                </div>
              ) : null
            }

            {
              allowBuy && (
                <div className='w-full bg-white py-2 lg:relative mt-14 pb-4 lg:rounded z-20'>
                  {
                    calculateTotal() > 0 ? (
                      <>
                        {
                          days.map(day => (
                            <div key={day.id}>
                              <div className='totals flex justify-between w-[80%] mx-auto pt-3 font-semibold text-lg'>
                                <span>Día de Entrega: </span>
                                <span>{day.name}</span>
                              </div>
                              <div className='totals flex justify-between w-[80%] mx-auto pb-5 font-bold text-lg border-gray-200 border-b border-solid'>
                                <span>Subtotal: </span>
                                <span>
                                  {calculateSubtotal(day.id).toLocaleString('es-AR', {
                                    style: 'currency',
                                    currency: 'ARS',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </span>
                              </div>
                            </div>
                          ))
                        }

                        {
                          discount > 0
                            ? (<div className='totals flex justify-between w-[80%] mx-auto py-5 font-bold text-lg border-gray-200 border-b border-solid text-green-600'>
                              <span className='font-medium'>Descuento: {discount}%</span>
                              <span>
                                - {(calculateTotal() * discount / 100).toLocaleString('es-AR', {
                                  style: 'currency',
                                  currency: 'ARS',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                            </div>) : null
                        }

                        <div className='totals flex justify-between w-[80%] mx-auto py-8 font-black text-lg'>
                          <span>TOTAL: </span>
                          <span>
                            {(calculateTotal() - (calculateTotal() * discount / 100)).toLocaleString('es-AR', {
                              style: 'currency',
                              currency: 'ARS',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </span>
                        </div>
                        {
                          editOrder ? (
                            <div className={`bg-gray-900 w-[80%] mx-auto text-white text-center flex justify-center rounded-full py-3 ${(calculateTotal() - (calculateTotal() * discount / 100)) < minimum_amount ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`} onClick={() => sendOrder(editOrder, selectedOrder)}>
                              {
                                sendingOrder ? (
                                  <svg className='animate-spin -ml-1 mr-3 h-5 w-5 text-white' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className='opacity-25' cx="12" cy="12" r="10" stroke="#FFFFFF" strokeWidth="4"></circle>
                                    <path className='opacity-75' fill="#FFFFFF" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                  </svg>
                                ) : (
                                  <span className='font-bold'>Editar pedido</span>
                                )
                              }
                            </div>
                          ) : (
                            <div className={`bg-gray-900 w-[80%] mx-auto text-white text-center flex justify-center rounded-full py-3 ${(calculateTotal() - (calculateTotal() * discount / 100)) < minimum_amount ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`} onClick={() => sendOrder()}>
                              {
                                sendingOrder ? (
                                  <svg className='animate-spin -ml-1 mr-3 h-5 w-5 text-white' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className='opacity-25' cx="12" cy="12" r="10" stroke="#FFFFFF" strokeWidth="4"></circle>
                                    <path className='opacity-75' fill="#FFFFFF" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                  </svg>
                                ) : (
                                  <span className='font-bold'>Hacer pedido</span>
                                )
                              }
                            </div>
                          )
                        }
                      </>
                    ) : (
                      <span className='py-6 px-4 text-center block'>Sumá cantidades para agregar productos a tu pedido.
                        <br />
                      </span>
                    )
                  }
                </div>
              )
            }

          </div>
        </div>
      </section>

      {
        statusOrder ? (
          <Modal handleClick={() => setStatusOrder(!statusOrder)}>
            <>
              <header className='mb-10'>
                <i className='fas fa-check-circle fa-2x text-green-600'></i>
                <span className='font-bold text-3xl pl-4'>Gracias</span>
              </header>
              <div className='mb-16'>
                <p className='font-medium'>Tu pedido se envió correctamente.</p>
                <p className='font-light'>Podes seguir el estado del mismo en la sección de Pedidos.</p>
              </div>
            </>
          </Modal>
        ) : null
      }
      {
        statusOrderError ? (
          <Modal handleClick={() => setStatusOrderError(!statusOrderError)}>
            <>
              <header className='mb-10'>
                <i className='fas fa-check-circle fa-2x text-red-600'></i>
                <span className='font-bold text-3xl pl-4'>Ups!</span>
              </header>
              <div className='mb-16'>
                <p className='font-medium'>Parece que hubo un error.</p>
                <p className='font-light'>Por favor contacta con Ruda Panadería.</p>
              </div>
            </>
          </Modal>
        ) : null
      }

    </>
  )
}

export default Products