import { useContext, useEffect, useState } from 'react'
import { OrdersContext } from '../context/OrdersProvider'
import { ProductsContext } from '../context/ProductsContext'
import { Link } from 'react-router-dom'
import moment from 'moment/moment'
import 'moment/locale/es'
import Navbar from '../components/layouts/Navbar'
import Header from '../components/layouts/Header'
import Alerts from '../components/Alerts'
import { useBlockDays } from '../hooks/blockDays'
import CalculateAmountWeekly from '../components/orders/CalculateAmountWeekly'
import Button from '../components/Button'

const Orders = () => {

  const {
    getOrders,
    orders,
    isBlocked,
    loadingOrders
  } = useContext(OrdersContext)

  const {
    setCart
  } = useContext(ProductsContext)

  const [openModals, setOpenModals] = useState(false)
  const [openWarning, setOpenWarning] = useState(false)

  const allowBuy = useBlockDays()
  const openPopup = (type) => {
    type === 'allowBuy' ? setOpenWarning(true) : setOpenModals(true)
  }

  useEffect(() => {
    getOrders()
    setCart([])
  }, [])

  return (
    <>
      <Navbar />
      {
        !allowBuy
          ? (<Header title={'Pedidos'} button={{ text: 'Nuevo pedido', link: '/products' }} action={() => openPopup('allowBuy')} />)
          : isBlocked
            ? (<Header title={'Pedidos'} button={{ text: 'Nuevo pedido', link: '/products' }} action={() => openPopup('isBlocked')} />)
            : (<Header title={'Pedidos'} button={{ text: 'Nuevo pedido', link: '/products' }} />)
      }

      {
        loadingOrders ? (
          <section className='main-section w-full flex items-center'>
            <svg className='animate-spin px-1 h-8 w-8 text-white mx-auto' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className='opacity-25' cx="12" cy="12" r="10" stroke="#E6AA41" strokeWidth="4"></circle>
              <path className='opacity-75' fill="#E6AA41" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </section>
        ) : (
          <section className='main-section px-4 w-full pb-24 min-h-screen pt-8'>
            {
              orders.length > 0 ? (
                <div className='max-w-7xl mx-auto flex flex-col lg:flex-wrap box-border lg:flex-row lg:px-8'>
                  {
                    orders.map(order => (
                      <div className='p-4 flex-col bg-white mb-3 lg:mb-6 rounded-md order-item' key={order.identifier}>
                        <h3 className='font-medium text-md mb-3 leading-5 text-left'>Pedido: {order.identifier} <span className='font-light'>{order.state ? ` - ${order.state === 'Entrega' ? 'Entregado' : order.state}` : null}</span></h3>
                        <h3 className='font-medium text-md mb-3 leading-5 text-left'>Fecha: {moment(order.date).format('L')} </h3>
                        <ul className='flex flex-row justify-between'>
                          <li className='font-bold'>
                            <CalculateAmountWeekly orders={order.orders} />
                            {
                              order.payed ? (
                                <span><i className='ml-4 text-green-500 fas fa-check-circle'></i></span>
                              ) : null
                            }
                          </li>
                          <li>
                            <Link to={`/orders/${order.identifier}`}
                              className='btn shadow text-center f-light px-3 py-1 text-white bg-blue rounded font-light cursor-pointer'>
                              Ver pedido
                            </Link>
                          </li>
                        </ul>
                      </div>
                    ))
                  }
                </div>
              ) : (
                <div className='max-w-6xl mx-auto flex flex-col lg:flex-wrap box-border'>
                  <div className='text-center pt-32'>
                    <h2 className='text-2xl font-bold mb-6'>Te damos <br />la bienvenida</h2>
                    <p className='mb-12'>No tenes pedidos Realizados.</p>

                    <Button
                      mode={'dark'}
                      action={'link'}
                      href={'/products'}
                      text={'Ver productos'}
                      clase={'flex mx-auto justify-center max-w-[200px]'}
                    />
                  </div>
                </div>
              )
            }
          </section>
        )
      }

      {
        openModals ? (
          <Alerts
            color={'text-red-500'}
            title={'Error'}
            text={'Por el momento no podes realizar más pedidos. Comunicate con Ruda Panadería para conocer el estado de la cuenta y habilitarla.'}
            handleClick={() => setOpenModals(false)}
          />
        ) : null
      }

      {
        openWarning ? (
          <Alerts
            color={'text-blue-500'}
            title={'Información'}
            text={'Los pedidos solo se pueden realizar a partir del viernes 18hs hasta el domingo 21hs.'}
            handleClick={() => setOpenWarning(false)}
          />
        ) : null
      }
    </>
  )
}

export default Orders