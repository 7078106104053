import React, { useContext, useState, useRef, useEffect } from 'react'
import Button from '../components/Button'
import Modal from '../components/Modal'
import { Navigate, NavLink } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import ReCAPTCHA from 'react-google-recaptcha'
import { toast } from 'react-toastify'
import { UserContext } from '../context/UserProvider'

const Register = () => {
  const {
    getCountries,
    getCities,
    registerUser,
    registered,
    countries,
    cities,
    setError,
    error,
  } = useContext(UserContext)
  const [ samePass, setSamePass ] = useState(true)
  const { register, setValue, handleSubmit, watch, formState: { errors } } = useForm()
  const captcha = useRef(null)

  const onChange = () => {
    const token = captcha.current.getValue()
    if (token) {
      setValue('captchaToken', token, { shouldValidate: true })
    }
  }

  const onSubmit = (data) => {
    toast.success('Formulario enviado', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    registerUser(data)
  }
  
  useEffect(() => {
    console.log("🚀 ~ file: Register.jsx:47 ~ useEffect ~ registered:", registered)
    if(registered) {
      <Navigate to='/successful-registration' />
    }
  }, [ registered ])

  useEffect(() => {
   getCountries()
  }, [])

  const verifyPassword = (e) => {
    const pass = watch('password')
    if(pass !== e.target.value){
      setSamePass(false)
    } else {
      setSamePass(true)
    }
  }

  const selectState = (e) => {
    const state = e.target.value
    getCities(state)
  }

  return (
    <>
      <section className='login section-container flex flex-col items-center p-10 xl:px-40'>
        <div className='login__text text-center mb-10'>
          <NavLink to={'/'}>
            <img
              src='../images/logo-ruda.jpeg'
              className='mx-auto w-[150px] mb-4'
              alt='Isologo Ruda'
            />
          </NavLink>
          <h1 className='text-2xl'>Crea tu cuenta</h1>
          <h4 className='c-white font-light text-base leading-5'>
            Completa el formulario con tu información <br />para darte de alta como cliente
          </h4>
        </div>
        <div className="login__form">
          <form className='xl:flex xl:flex-wrap' onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mb-4 xl:w-1/2 xl:basis-1/2 xl:px-3">
              <label className='w-full font-medium'>Nombre o Razón Social *</label>
              <input
                type="text"
                className='form-control w-full bg-transparent rounded-md border border-solid border-slate-300 h-[40px] px-3'
                {...register("name", { required: true })}
              />
              {
                errors.name && ( 
                  <span className='input-error text-red-500 text-sm font-thin flex'>
                    Tenes que ingresar Razón Social
                  </span>
                )
              }
            </div>
            <div className="form-group mb-4 xl:w-1/2 xl:basis-1/2 xl:px-3">
              <label className='w-full font-medium'>CUIT *</label>
              <input
                type="number"
                className='form-control w-full bg-transparent rounded-md border border-solid border-slate-300 h-[40px] px-3'
                {...register("cuit", { required: true })}
              />
              {
                errors.cuit && ( 
                  <span className='input-error text-red-500 text-sm font-thin flex'>
                    Tenes que ingresar el CUIT
                  </span>
                )
              }
            </div>
            <div className="form-group mb-4 xl:w-1/2 xl:basis-1/2 xl:px-3">
              <label className='w-full font-medium'>E-mail *</label>
              <input
                type="email"
                className='form-control w-full bg-transparent rounded-md border border-solid border-slate-300 h-[40px] px-3'
                {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
              />
              {
                errors.email && ( 
                  <span className='input-error text-red-500 text-sm font-thin flex'>
                    Tenes que ingresar tu E-mail
                  </span>
                )
              }
              <span className='mt-1 flex items-center text-slate-500 text-sm'>
                <input
                  type="checkbox"
                  className='form-control rounded-md border border-solid border-slate-300 w-4 h-4 mr-2'
                  onChange={
                    () => setValue('charge_email', watch('email'), { shouldValidate: true })
                  }
                />
                Mismo Correo que el responsable
              </span>
            </div>
            <div className="form-group mb-4 xl:w-1/2 xl:basis-1/2 xl:px-3">
              <label className='w-full font-medium'>Teléfono *</label>
              <input
                type="tel"
                className='form-control w-full bg-transparent rounded-md border border-solid border-slate-300 h-[40px] px-3'
                {...register("telephont", { required: true })}
              />
              {
                errors.telephont && ( 
                  <span className='input-error text-red-500 text-sm font-thin flex'>
                    Tenes que ingresar un Teléfono
                  </span>
                )
              }
              <span className='mt-1 flex items-center text-slate-500 text-sm'>
                <input
                  type="checkbox"
                  className='form-control rounded-md border border-solid border-slate-300 w-4 h-4 mr-2'
                  onChange={
                    () => setValue('charge_telephont', watch('telephont'), { shouldValidate: true })
                  }
                />
                Mismo Teléfono que el responsable
              </span>
            </div>
            <div className="form-group mb-4 xl:w-1/2 xl:basis-1/2 xl:px-3">
              <label className='w-full font-medium'>Provincia *</label>
              <select
                className='form-control w-full bg-transparent rounded-md border border-solid border-slate-300 h-[40px] px-3'
                {...register("state", { required: true })}
                onChange={(e) => selectState(e)}>
                  {/* <option disabled selected value="">Seleccionar Provincia</option> */}
                  {
                    countries.map(state => (
                      <option key={state.id} value={state.id}>{state.name}</option>
                    ))
                  }

              </select>
              {
                errors.state && ( 
                  <span className='input-error text-red-500 text-sm font-thin flex'>
                    Tenes que elegir una Provincia
                  </span>
                )
              }
            </div>
            <div className="form-group mb-4 xl:w-1/2 xl:basis-1/2 xl:px-3">
              <label className='w-full font-medium'>Localidad *</label>
              <select
                className='form-control w-full bg-transparent rounded-md border border-solid border-slate-300 h-[40px] px-3'
                {...register("city_id", { required: true })}>
                  {/* <option disabled selected value="">Seleccionar Localidad</option> */}
                  {
                    cities.map(city => (
                      <option key={city.id} value={city.id}>{city.name}</option>
                    ))
                  }

              </select>
              {
                errors.city && ( 
                  <span className='input-error text-red-500 text-sm font-thin flex'>
                    Tenes que elegir una Localidad
                  </span>
                )
              }
            </div>
            <div className="form-group mb-4 xl:w-1/2 xl:basis-1/2 xl:px-3">
              <label className='w-full font-medium'>Dirección *</label>
              <input
                type="text"
                className='form-control w-full bg-transparent rounded-md border border-solid border-slate-300 h-[40px] px-3'
                {...register("address", { required: true })}
              />
              {
                errors.address && ( 
                  <span className='input-error text-red-500 text-sm font-thin flex'>
                    Tenes que ingresar una Dirección
                  </span>
                )
              }
              <span className='mt-1 flex items-center text-slate-500 text-sm'>
                <input
                  type="checkbox"
                  className='form-control rounded-md border border-solid border-slate-300 w-4 h-4 mr-2'
                  onChange={
                    () => setValue('charge_address', watch('address'), { shouldValidate: true })
                  }
                />
                Misma Dirección que el responsable
              </span>
            </div>
            <div className="form-group mb-4 xl:w-1/2 xl:basis-1/2 xl:px-3">
              <label className='w-full font-medium'>Nombre de el/la responsable *</label>
              <input
                type="text"
                className='form-control w-full bg-transparent rounded-md border border-solid border-slate-300 h-[40px] px-3'
                {...register("charge_name", { required: true })}
              />
              {
                errors.charge_name && ( 
                  <span className='input-error text-red-500 text-sm font-thin flex'>
                    Tenes que ingresar un Nombre
                  </span>
                )
              }
            </div>
            <div className="form-group mb-4 xl:w-1/2 xl:basis-1/2 xl:px-3">
              <label className='w-full font-medium'>E-mail de el/la responsable *</label>
              <input
                type="email"
                className='form-control w-full bg-transparent rounded-md border border-solid border-slate-300 h-[40px] px-3'
                {...register("charge_email", { required: true })}
              />
              {
                errors.charge_email && ( 
                  <span className='input-error text-red-500 text-sm font-thin flex'>
                     Tenes que ingresar un E-mail
                  </span>
                )
              }
            </div>
            <div className="form-group mb-4 xl:w-1/2 xl:basis-1/2 xl:px-3">
              <label className='w-full font-medium'>Teléfono de el/la responsable *</label>
              <input
                type="tel"
                className='form-control w-full bg-transparent rounded-md border border-solid border-slate-300 h-[40px] px-3'
                {...register("charge_telephont", { required: true })}
              />
              {
                errors.charge_telephont && ( 
                  <span className='input-error text-red-500 text-sm font-thin flex'>
                     Tenes que ingresar un Teléfono
                  </span>
                )
              }
            </div>
            <div className="form-group mb-4 xl:w-1/2 xl:basis-1/2 xl:px-3">
              <label className='w-full font-medium'>Dirección de entrega *</label>
              <input
                type="text"
                className='form-control w-full bg-transparent rounded-md border border-solid border-slate-300 h-[40px] px-3'
                {...register("charge_address", { required: true })}
              />
              {
                errors.charge_address && ( 
                  <span className='input-error text-red-500 text-sm font-thin flex'>
                     Tenes que ingresar una Dirección
                  </span>
                )
              }
            </div>

            <div className="form-group mb-4 xl:w-1/2 xl:basis-1/2 xl:px-3">
              <label className='w-full font-medium'>Contraseña *</label>
              <input
                type="password"
                className='form-control w-full bg-transparent rounded-md border border-solid border-slate-300 h-[40px] px-3'
                {...register("password", { required: true })}
              />
              {
                errors.password && ( 
                  <span className='input-error text-red-500 text-sm font-thin flex'>
                    Tenes que ingresar una contraseña
                  </span>
                )
              }
            </div>

            <div className="form-group mb-4 xl:w-1/2 xl:basis-1/2 xl:px-3">
              <label className='w-full font-medium'>Repetir Contraseña *</label>
              <input
                type="password"
                className='form-control w-full bg-transparent rounded-md border border-solid border-slate-300 h-[40px] px-3'
                {...register("re_password", { required: true })}
                onChange={(e) => verifyPassword(e)}
              />
              {
                errors.re_password && ( 
                  <span className='input-error text-red-500 text-sm font-thin flex'>
                    Tenes que ingresar nuevamente la contraseña
                  </span>
                )
              }
              {
                !samePass && ( 
                  <span className='input-error text-red-500 text-sm font-thin flex'>
                    Las contraseñas deben coincidir
                  </span>
                )
              }
            </div>
            
            <div className='form-group mb-4 w-full text-center'>
              <ReCAPTCHA
                className='recaptcha flex justify-center'
                ref={captcha}
                sitekey='6LeH6FshAAAAAI7VY_99057vWiDIi4FxnljooCbF'
                onChange={onChange}
              />
            </div>
            <div className="input-group mb-4 xl:mb-12 xl:mx-auto xl:mt-5">
              <Button
                mode={'dark'}
                type={'submit'}
                text={'Solicitar registro'}
                clase={'block mx-auto'}
              />
            </div>
          </form>
        </div>
      </section>

      {
        error ? (
          <Modal handleClick={() => setError(null)}>
            <>
              <header className='mb-10'>
                <i className='fas fa-check-circle fa-2x text-red-600'></i>
                <span className='font-bold text-3xl pl-4'>Error!</span>
              </header>
              <div className='mb-16'>
                <p className='font-medium'>{error}</p>
              </div>
            </>
          </Modal>
        ) : null
      }

    </>
  )
}

export default Register