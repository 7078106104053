import React from 'react'

const Loader = () => {
  return (
    <section className='main-section w-full flex items-center h-screen'>
      <svg className='animate-spin px-1 h-8 w-8 text-white mx-auto' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className='opacity-25' cx="12" cy="12" r="10" stroke="#E6AA41" strokeWidth="4"></circle>
        <path className='opacity-75' fill="#E6AA41" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </section>
  )
}

export default Loader