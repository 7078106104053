import { createContext, useState } from 'react'
import { getOrdersService } from '../services/ordersService'

// Definir el contexto de las órdenes
export const OrdersContext = createContext()

export const OrdersProvider = ({ children }) => {
  // Aquí puedes definir el estado y la lógica relacionados con las órdenes
  const [orders, setOrders] = useState([])
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [isBlocked, setIsBlocked] = useState(false)
  const [loadingOrders, setLoadingOrders] = useState(true)
  const [loadingOrder, setLoadingOrder] = useState(true)

  // Lógica para obtener órdenes de la API, similar a lo que hiciste en ProductsProvider
  const groupOrdersByWeek = (orders) => {
    const groups = orders.reduce((groups, pedido) => {
      const identifier = pedido.identifier;
      if (!groups[identifier]) {
        groups[identifier] = [];
      }
      groups[identifier].push(pedido);
      return groups;
    }, {})
    const groupArrays = Object.keys(groups).map((identifier) => {
      return {
        identifier,
        orders: groups[identifier]
      }
    })
    const ordersDesc = [...groupArrays].sort((a, b) => b.identifier - a.identifier)
    const ordersWithState = ordersDesc.map(order => {
      if (order.length > 1) {
        return {
          ...order,
          state: order.orders.length > 1
                ? order.orders[0].state === order.orders[1].state ? order.orders[0].state : null
                : order.orders[0].state,
          payed: order.orders[0].payed,
          date: order.orders[0].created_at
        }
      } else {
        return {
          ...order,
          state: order.orders[0].state,
          payed: order.orders[0].payed,
          date: order.orders[0].created_at
        }
      }
    })

    return ordersWithState
  }

  const showOrderDetails = (orderId) => {
    const item = JSON.parse(localStorage.getItem('item'))
    if ( item && item.identifier === orderId) {
      setSelectedOrder(item)
    } else {
      const order = orders.filter(item => item.identifier === orderId)
      localStorage.setItem('item', JSON.stringify(order[0]))
      setSelectedOrder(order[0])
    }
    setLoadingOrder(false)
  }

  const getOrders = async ( ) => {
    try {
      const orders = await getOrdersService()
      const groupedOrders = groupOrdersByWeek(orders)
      setOrders(groupedOrders)
      const payed = groupedOrders.filter(order => order.payed !== 1);
      setIsBlocked(payed.length > 1)
      setLoadingOrders(false)
    } catch (error) {
      console.log("🚀 ~ file: OrdersProvider.jsx:78 ~ fetchData ~ error:", error)
      setLoadingOrders(false)
    }
  }

  return (
    <OrdersContext.Provider value={{
        getOrders,
        orders,
        isBlocked,
        loadingOrders,
        selectedOrder,
        loadingOrder,
        showOrderDetails,
      }}>
      {children}
    </OrdersContext.Provider>
  )
}
