import React, { useContext, useState, useEffect } from 'react'
import { UserContext } from '../../context/UserProvider'

const CalculateAmountWeekly = ( { orders } ) => {

  const { user } = useContext(UserContext)
  const [ amount, setAmount ] = useState(0)
  const { discount } = user

  
  useEffect(() => {
    const calculate = () => {
      let total = 0
      for (let i = 0; i < orders.length; i++) {
        for (let index = 0; index < orders[i].order_details.length; index++) {
          total = total + orders[i].order_details[index].product_price * orders[i].order_details[index].quantity
        }
      }
      setAmount(total)
    }
  
    calculate()
  }, [ orders, amount ])
  
  return (
    <>
      $ {(amount - (amount * discount / 100)).toLocaleString('es-AR')}
    </>
  )
}

export default CalculateAmountWeekly