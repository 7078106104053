import moment from 'moment'

export const useBlockDays = () => {
  let allowBuy = true // Forzar compra
  // let allowBuy = false

  const startDateTime = moment().day(5).hour(18).minute(0).second(0)
  const endDateTime = moment().day(0).hour(21).minute(0).second(0).add(1, 'week')
  const currentDateTime = moment()

  if (currentDateTime.isBetween(startDateTime, endDateTime)) {
    allowBuy = true // Se puede comprar
  }

  return allowBuy
};
