import { useState, useEffect, useContext } from 'react'
import { ProductsContext } from '../../context/ProductsContext'

const Presentation = ( { id } ) => {

  const [ presentation, setPresentation ] = useState()
  const { products } = useContext(ProductsContext)

  useEffect(() => {
    const calculate = () => {
      const items = products.filter(p => p.id === id)
      if (items.length > 0) {
        setPresentation(`${items[0].value} ${items[0].presentation}`)
      }
      setPresentation(`-`)
    }
  
    calculate()
  }, [ products, setPresentation, id ])
  
  return presentation
}

export default Presentation