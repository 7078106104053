import React from "react"

const Modal = ( { children, handleClick } ) => {
  return (
    <>
      <div className='overlay fixed top-0 left-0 bg-black bg-opacity-75 w-full h-full z-50 flex items-center'>
        <div className='modals mx-4 bg-white rounded-md w-full flex px-4 pb-5 lg:max-w-lg lg:mx-auto relative max-w-full pt-10 flex-col items-center justify-center text-center'>
          <span className='absolute top-2 right-4 cursor-pointer text-2xl' onClick={() => handleClick()}>x</span>
          { children }
        </div>
      </div>
    </>
  )
}

export default Modal