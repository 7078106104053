import { useContext } from 'react'
import { BrowserRouter, Routes, Route, useLocation, Navigate, Outlet } from 'react-router-dom'
import Login from '../pages/Login'
import Products from '../pages/Products'
import Orders from '../pages/Orders'
import Register from '../pages/Register'
import Registered from '../pages/Registered'
import OrderDetails from '../pages/OrderDetails'
import Account from '../pages/Account' 
import ScrollToTop from '../hooks/ScrollToTop'
import { UserContext } from '../context/UserProvider'
import Loader from '../components/layouts/Loader'


const Router = () => {
  const { isLogged } = useContext(UserContext)

  const RequireAuth = () => {
    let location = useLocation()
    if (!isLogged) {
      return <Navigate to="/login" state={{ from: location }} />
    }
    return <Outlet />
  }

  
  if ( isLogged === null ) {
    return (
      <Loader />
    )
  } else {

    return (
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path='/login' element={ <Login /> } />
            <Route path='/register' element={ <Register /> } />
            <Route path='/successful-registration' element={ <Registered /> } />
            <Route element={<RequireAuth />}>
              <Route path='/orders' element={ <Orders /> } />
              <Route path='/orders/:idendifier' element={ <OrderDetails /> } />
              <Route path='/products' element={ <Products /> } />
              <Route path='/account' element={ <Account /> } />
            </Route>
            <Route path='*' element={ <Navigate to='/login' /> } />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    )
  }

}

export default Router