import React, { useState, useEffect } from 'react'

const CalculateAmount = ( { products, discount } ) => {

  const [ amount, setAmount ] = useState(0)

  useEffect(() => {
    const calculate = () => {
      let total = 0
      for (let index = 0; index < products.length; index++) {
        total = total + products[index].product_price * products[index].quantity
      }
      setAmount(total)
    }
  
    calculate()
  }, [ products, amount ])
  
  return (
    <>
      $ { (amount - (amount * discount / 100)).toLocaleString('es-AR') }
    </>
  )
}

export default CalculateAmount