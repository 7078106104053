import axios from 'axios'
import Cookies from 'js-cookie'

export const getInfo = async () => {
  const storedToken = Cookies.get('token')
  const headers = {
    'Accept': 'application/json',
    'Authorization': `Bearer ${storedToken}`
  }
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/info-general`, { headers })
    return response.data
  } catch (error) {
    throw error
  }
}
