import { createContext, useEffect, useState } from 'react'
import {
  loginService,
  getDataUserService,
  getCountriesService,
  getCitiesService,
  registerUserService } from '../services/userService'
import Cookies from 'js-cookie'

export const UserContext = createContext()

export const UserProvider = ({ children }) => {

  const [isLogged, setIsLogged] = useState(null)
  const [userLoading, setUserLoading] = useState(false)
  const [registered, setRegistered] = useState(null)
  const [countries, setCountries] = useState([])
  const [cities, setCities] = useState([])
  const [user, setUser] = useState(null)
  const [error, setError] = useState(null)

  const loginUser = async ( userData ) => {
    setUserLoading(true)
    try {
      const data  = await loginService(userData)
      if ( data ) {
        setUserLoading(false)
        setIsLogged(true)
        setUser(data.client)
        // setToken(data.token)
        return true
      }
    } catch (error) {
      setUserLoading(false)
      throw error
    }
  }

  const getUserData = async ( token, email ) => {
    try {
      const data = await getDataUserService(token, email)
      if ( data ) {
        setUser(data)
        setIsLogged(true)
        setUserLoading(false)
      }
    } catch (error) {
      setUserLoading(false)
      throw error
    }
  }

  const logoutUser = () => {
    Cookies.remove('token')
    Cookies.remove('email')
    Cookies.remove('clientId')
    setIsLogged(false)
    setUser(null)
    return true
  }

  const getCities = async ( country ) => {
    try {
      const { data, error } = await getCitiesService(country)
      if ( error ) {
        return setError("Error al cargar las Localidades.")
      }
      setCities(data)
    } catch (error) {
      setError("Error al cargar las Localidades.")
      console.log("🚀 ~ file: UserProvider.jsx:67 ~ getCountries ~ error:", error)
      throw error
    }
  }

  const getCountries = async () => {
    try {
      const { data, error } = await getCountriesService()
      if ( error ) {
        return setError("Error al cargar las Provincias.")
      }
      setCountries(data)
      getCities(data[0].id)
    } catch (error) {
      setError("Error al cargar las localidades.")
      console.log("🚀 ~ file: UserProvider.jsx:67 ~ getCountries ~ error:", error)
      throw error
    }
  }

  const registerUser = async ( formData ) => {
    try {
      const { data, error } = await registerUserService(formData)
      if (error || data.message === 'Se ha producido un error !') {
        return setError("Parare que algo anda mal. Comunicate con Ruda Panadería por favor.")
      }
      setRegistered(true)
    } catch (error) {
      console.log("🚀 ~ file: UserProvider.jsx:104 ~ registerUser ~ error:", error)
      setError("Parare que algo anda mal. Comunicate con Ruda Panadería por favor.")
      throw error
    }
  }

  useEffect(() => {
    const getUserAction = () => {
      const storedToken = Cookies.get('token')
      const storedEmail = Cookies.get('email')
      if (storedToken && storedEmail) {
        getUserData(storedToken, storedEmail)
      } else {
        setIsLogged(false)
      }
    }
    getUserAction()
  }, [])

  return (
    <UserContext.Provider value={{
      loginUser,
      logoutUser,
      getCountries,
      getCities,
      registerUser,
      isLogged,
      userLoading,
      user,
      countries,
      cities,
      registered,
      setError,
      error,
    }}>
      {children}
    </UserContext.Provider>
  )
}