import axios from 'axios'
import Cookies from 'js-cookie'

export const loginService = async ( data ) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, data, {
      headers: { 
        'Accept': 'application/json',
      }
    })
    if ( response.data.token ) {
      Cookies.set('token', response.data.token, { expires: 7 })
      Cookies.set('email', data.email, { expires: 7 })
      Cookies.set('clientId', response.data.client.id, { expires: 7 })
      return response.data
    }
  } catch (error) {
    throw error
  }
}

export const getDataUserService = async (token, email) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/clients/${email}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    })
    Cookies.set('clientId', response.data.data.id, { expires: 7 })
    return response.data.data
  } catch (error) {
    throw error
  }
}

export const getCountriesService = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/states`, {
      headers: {
        'Accept': 'application/json'
      }
    })
    return response.data
  } catch (error) {
    return error
  }
}

export const getCitiesService = async ( country ) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/cities/${country}`, {
      headers: {
        'Accept': 'application/json'
      }
    })
    return response.data
  } catch (error) {
    return error
  }
}

export const registerUserService = async ( formData ) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/clients`, formData, {
      headers: { 
        'Accept': 'application/json',
      }
    })
    return response
  } catch (error) {
    return error
  }
}