import React from 'react'
import Button from '../Button'

const Header = ( { title, button, action = 'link' } ) => {
  return (
    <header className="bg-white shadow">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
        <h2 className="font-regular text-xl text-gray-800 leading-tight">
          { title }
        </h2>
        { button && (
          <Button
            mode={'dark'}
            action={action}
            href={button.link}
            text={button.text}
          />
        )}
      </div>
    </header>
  )
}

export default Header