import axios from 'axios'
import Cookies from 'js-cookie'

export const getOrdersService = async ( ) => {
  const storedClientId = Cookies.get('clientId')
  const storedToken = Cookies.get('token')
  const headers = {
    'Accept': 'application/json',
    'Authorization': `Bearer ${storedToken}`
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/clients/${storedClientId}/orders`, { headers })
    return response.data.orders
  } catch (error) {
    console.log("🚀 ~ file: orderService.js:19 ~ getOrders ~ error:", error)
    return error
  }
}

export const sendProducToUpdateService = async (product) =>  {
  const storedToken = Cookies.get('token')
  const headers = {
    'Accept': 'application/json',
    'Authorization': `Bearer ${storedToken}`
  }
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/details/${product.line_id}/edit`, product, { headers })
    return response.data
  } catch (error) {
    console.log("🚀 ~ file: ordersService.js:29 ~ sendProducToUpdateService ~ error:", error)
    return error
  }
}

export const sendProducToAddService = async (product) =>  {
  const storedToken = Cookies.get('token')
  const headers = {
    'Accept': 'application/json',
    'Authorization': `Bearer ${storedToken}`
  }
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/details`, product, { headers })
    return response.data
  } catch (error) {
    console.log("🚀 ~ file: ordersService.js:39 ~ sendProducToAddService ~ error:", error)
    return error
  }
}

export const sendProducToRemoveService = async (line_id) =>  {
  const storedToken = Cookies.get('token')
  const headers = {
    'Accept': 'application/json',
    'Authorization': `Bearer ${storedToken}`
  }
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/details/${line_id}`, { headers })
    return response.data
  } catch (error) {
    console.log("🚀 ~ file: ordersService.js:49 ~ sendProducToRemoveService ~ error:", error)
    return error
  }
}

export const sedOrderServices = async (order) =>  {
  const storedToken = Cookies.get('token')
  const headers = {
    'Accept': 'application/json',
    'Authorization': `Bearer ${storedToken}`
  }
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/orders`, order, { headers })
    return response
  } catch (error) {
    console.log("🚀 ~ file: ordersService.js:58 ~ sendOrderEdit ~ error:", error)
    return error
  }
}