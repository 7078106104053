import Thanks from '../components/Thanks'

const Registered = () => {
  return (
    <Thanks 
      textLine1={'Tu solicitud se envió correctamente.'}
      textLine2={'Vamos a revisar tu información y contactarte para terminar tu registro.'}
      textButton={'Ir al incio'}
      linkButton={'/login'}
    />
  )
}

export default Registered