import React from 'react'

const Alerts = ( { color, title, text, handleClick } ) => {
  return (
    <>
      <div className='overlay fixed top-0 left-0 bg-black bg-opacity-75 w-full h-full z-50 flex items-center'>
        <div className='modals mx-4 bg-white rounded-md w-full flex px-3 py-5 lg:max-w-lg lg:mx-auto'>
          <div className={`${color}`}>
            <i className='fas fa-exclamation-circle fa-2x lg:fa-3x'></i>
          </div>
          <div className='ml-4 text-left'>
            <h3 className='mt-1 mb-2 font-bold text-lg'>{title}</h3>
            <p className='text-slate-500 font-light leading-5'>{text}</p>
            <ul className='flex justify-end mt-4'>
              <li className='border border-solid border-slate-300 rounded px-3 py-1 mr-4 cursor-pointer' onClick={handleClick}>
                Aceptar
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Alerts