import { useContext } from 'react'
import Button from '../components/Button'
import Loader from '../components/layouts/Loader'
import { Link, NavLink, Navigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { UserContext } from '../context/UserProvider'

const Login = ( ) => {

  const { isLogged, userLoading, loginUser } = useContext(UserContext)
  const { register, handleSubmit, formState: { errors } } = useForm()

  if (isLogged) {
    return <Navigate to='/orders' />
  }

  const onSubmit = async data => {
    const toastOptions = {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }

    const response = await loginUser(data)
    if (response) {
      toast.success("Sesión iniciada con éxito", toastOptions)
    } else {
      toast.error("Datos incorrectos o cuenta inhabilitada", toastOptions)
    }
  }

  return (
    <>
      {
        userLoading && (
          <Loader />
        )
      }
      <section className='login section-container flex flex-col justify-center items-center h-screen p-10 xl:px-40'>
        <div className='login__text text-center mb-10'>
          <NavLink to={'/'}>
            <img
              src='../images/logo-ruda.jpeg'
              className='mx-auto w-[150px] mb-3'
              alt='Isologo Ruda'
            />
          </NavLink>
          <h4 className='c-white font-light text-base leading-5'>
            Inicia sesión con tu cuenta
            <br />para realizar tus pedidos.
          </h4>
        </div>
        <div className="login__form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mb-4">
              <label className='w-full font-medium'>Correo Electrónico</label>
              <input
                type="email"
                className='form-control w-full bg-transparent rounded-md border border-solid border-slate-300 h-[40px] px-3'
                {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
              />
              {
                errors.email && ( 
                  <span className='input-error text-red-500 text-sm font-thin flex'>
                    Tenes que ingresar tu E-mail válido
                  </span>
                )
              }
            </div>
            <div className="form-group mb-4">
              <label className='w-full font-medium'>Contraseña</label>
              <input
                type="password"
                className='form-control w-full bg-transparent rounded-md border border-solid border-slate-300 h-[40px] px-3'
                {...register("password", { required: true })}
              />
              {
                errors.password && ( 
                  <span className='input-error text-red-500 text-sm font-thin flex'>
                    Tenes que ingresar tu contraseña
                  </span>
                )
              }
            </div>
            <div className='input-group text-right mb-3'>
              <NavLink to={'/reset-password'}>
                <span className='text-blue-600 text-sm'>¿Olvidaste tu contraseña?</span>
              </NavLink>
            </div>
            <div className="input-group mb-4">
            {
              userLoading ? (
                <button className='btn shadow text-center f-light btn--dark block mx-auto cursor-wait'>
                  Cargando...
                </button>
              ) : (
                <Button
                  mode={'dark'}
                  type={'submit'}
                  text={'Iniciar sesión'}
                  clase={'block mx-auto'}
                />
              )
            }
            </div>
            <div className="reset-password text-center">
              <Link to={'/register'} >
                <p className='f-regular inline-block line line--bottom line--dark'>
                  Solicitar cuenta
                </p>
              </Link>
            </div>
          </form>
        </div>
      </section>
    </>
  )
}

export default Login